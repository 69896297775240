import React from 'react';
import Layout from '../components/Layout';
import Header from '../components/Header';
import Content from '../components/Content';
import Seo from '../components/SEO';

function About() {
  return (
    <Layout>
      <Seo />
      <Header title="Acerca" />
      <Content>
        <p>
          El principal objetivo de Cicerone es explicar custiones de gramática, sintaxis y
          ortografía sencilla y rápidamente; de tal manera que cuando tengas una duda, no
          te demores mucho tiempo en resolverla. Principalmente esta orientado a español,
          pero también escribimos sobre alemán, latín, inglés e italiano.
        </p>
        <br />
        <p>
          Nos basamos en los recursos oficiales que provee la RAE (para español), asi que
          aquí no veras un refrito de otros blogs. De la misma manera lo hacemos con los
          otros idiomas, nos vamos a recursos oficiales, para tener la verdad de las cosas.
        </p>
        <br />
        <p className="center">
          *
        </p>
        <br />
        <p>
          ¡Hola!
          {' '}
          <span role="img" aria-label="hello">😃</span>
          Me llamo Jorge Guerra, pero mis amigos me dicen Coco, soy de Guatemala. Soy el
          creador de Cicerone, ahora te cuento un poco sobre mi.
        </p>
        <br />
        <p>
          Hace 6 años estuve en una actividad internacional con personas de Polonia, China,
          Francia y muchos países más pero, ¿Adivina qué? No pude hablar con nadie, solo con
          personas que hablaban español. Muchas personas intentaban hablar conmigo, pero no
          podía responder nada. Un poco frustrante ¿no?
        </p>
        <br />
        <p>
          Cuando regrese a mi país, le conté a mi mejor amigo lo que me había pasado… Entonces el me
          respondió:
          <i>“Entonces de hoy en adelante te hablaré solo en inglés”</i>
          {' '}
          y así fue como todo comenzó.
        </p>
        <br />
        <p>
          Solía tener mucho miedo y pena de hablar en otro idioma distinto a mi idioma materno,
          y sobre todo pensaba que los idiomas eran para personas con habilidades excepcionales.
        </p>
        <br />
        <p>
          Luego de aproximadamente 1 año de luchar contra los nervios y de forzarme para responder
          en inglés, parecía que, finalmente aquello que yo consideraba inalcanzable se acercaba
          cada vez mas. Luego de un año y medio de mucho esfuerzo y dedicación logré una alta
          fluidez en inglés.
        </p>
        <br />
        <p>
          Una vez llegué a ese punto pensé:
          {' '}
          <i>si ya pude inglés, ¿por qué no intento otro?</i>
          Y en ese mismo momento comencé a aprender Alemán.
        </p>
        <br />
        <p>
          Todo lo que sé sobre mi idioma materno, inglés y alemán (y de algunos otros),
          no es más que el resultado de leer muchos recursos en internet, que me han ayudado a
          llegar a este punto y, para comprender mejor todo lo que un idioma conlleva he recibido
          muchísima ayuda del Prof. Rocael Recinos, quien fue mi profesor de español
          en el colegio y del Pbro. Eddy Palacios que me ha dado clases de latín.
        </p>
        <br />
        <p>
          No he estudiado idiomas en ninguna academia, no soy ningún experto, solo un gran
          entusiasta de los idiomas.
        </p>
        <br />
        <p>
          El conocimiento que he acumulado a lo largo de estos pocos años deseo ahora compartir
          lo contigo porque, ¡todos podemos aprender el idioma que queramos!
        </p>
        <br />
        <b>
          <p>
            Toda sugerencia es siempre bienvenida.
            <span role="img" aria-label="hello">🍻</span>
          </p>
        </b>
        <br />
        <h2>Contacto</h2>
        <span role="img" aria-label="hello">
          ✉️
          <b>Email</b>
          : jorgeguerra1098@gmail.com
        </span>
      </Content>
    </Layout>
  );
}

export default About;
